interface ISegment {
  page: <T extends object>(input: string, props?: T) => void
  virtualPageView: (path: string) => void
  track: (eventName: string) => void
  identify: (id: string | null, data: IIdentityData) => void
}

interface IIdentityData {
  merchant: string
  isEmployee: boolean
  buyerId?: string
}

interface ISegmentWindow {
  analytics: ISegment
  hj: any
}

const isSegmentWindow = (win: any): win is ISegmentWindow => {
  return win.analytics !== undefined
}

const mockSegmentWindow: ISegmentWindow = {
  analytics: {
    page: <T extends object>(input: string, props?: T): void => {
      console.log(`Tracking page view: ${JSON.stringify(input)} - ${JSON.stringify(props)}`)
    },
    virtualPageView: (path: string) => {
      console.log(`Tracking virtual page view: ${path}`)
    },
    track: (eventName: string) => {
      console.log(`Tracking event: ${eventName}`)
    },
    identify: (id: string | null, data: IIdentityData) => {
      console.log(`Identifying user. id: ${id || 'no id provided'}. data: ${JSON.stringify(data)}`)
    }
  },
  hj: undefined
}

class SegmentWrapperImpl implements ISegment {
  private segmentWindow?: ISegmentWindow

  constructor() {
    this.segmentWindow = isSegmentWindow(window) ? window : mockSegmentWindow
  }

  page = <T extends object>(input: string, props?: T): void => {
    this.segmentWindow?.analytics.page(input, props || {})
  }

  virtualPageView = <T extends object>(path: string, props?: T, isGoogleAnalytics?: boolean): void => {
    if (isGoogleAnalytics === true) {
      this.segmentWindow?.analytics.page(path, props)
    }

    if (typeof this.segmentWindow?.hj === 'function' && this.segmentWindow?.hj !== undefined) {
      this.segmentWindow?.hj('vpv', `/${path}/`)
      this.segmentWindow?.hj('trigger', path)
      this.segmentWindow?.hj('tagRecording', [`/${path}/`])
    }
  }

  track = (eventName: string): void => {
    if (this.segmentWindow?.analytics) {
      this.segmentWindow.analytics.track(eventName)
    } else {
      console.error('Segment analytics is not initialized.')
    }
  }

  identify = (id: string | null, data: IIdentityData): void => {
    if (this.segmentWindow?.analytics) {
      this.segmentWindow.analytics.identify(id, data)
    } else {
      console.error('Segment analytics is not initialized.')
    }
  }
}

const SegmentWrapper = new SegmentWrapperImpl()
export default SegmentWrapper
