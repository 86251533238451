import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const OrderNotFound = () => {
  useTrackEvent(HotJarEventType.ORDER_NOT_FOUND)

  return (
    <OrderErrors>
      <ErrorMessage>Something went wrong! Please head back to the page you were on.</ErrorMessage>
    </OrderErrors>
  )
}

export default OrderNotFound
