import React from 'react'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'
import Loading from '../components/common/loading/Loading'
import { useOrderInfo } from '../custom-hooks/useOrderInfo'
import Payment from './payment/Payment'
import PageGuard from '../utils/pageGuard'
import theme from '../assets/theme'
import Box from '@mui/material/Box'
import { TerminalPaymentJourneyStatuses, PaymentJourneyStatus } from '../types/enums/PaymentJourneyStatus'
import Logo from '../components/common/logo/Logo'
import CustomerDetails from './customer-details/CustomerDetails'
import Ineligible from './error/Ineligible'
import InsufficientFunds from './error/InsufficientFunds'
import InsufficientFundsOther from './error/InsufficientFundsOther'
import InvalidOrderStatus from './error/InvalidOrderStatus'
import InReview from './error/InReview'
import MfaConfirmationPolling from './mfa/MfaConfirmationPolling'
import MfaExpired from './mfa/MfaExpired'
import MfaRejected from './mfa/MfaRejected'
import MfaNotSupported from './mfa/MfaNotSupported'
import MfaApproved from './mfa/MfaApproved'
import InsufficientFundsOnboardingChecksAreRequired from './error/InsufficientFundsOnboardingChecksAreRequired'
import OnboardingChecksAreFailed from './error/OnboardingChecksAreFailed'
import useIdentify from '../custom-hooks/useIdentify'

const Order = () => {
  const currentPage = useMatch('/:shortCode/*')
  const { data, isLoading, error } = useOrderInfo()
  useIdentify(data)

  if (isLoading) return <Loading />

  if (error) return <Navigate to="/error" />

  if (!data) return <Navigate to="/not-found" />

  const redirect = PageGuard(currentPage, data.paymentJourney)
  if (redirect) return redirect

  return (
    <>
      {!TerminalPaymentJourneyStatuses.includes(data.paymentJourney.status as PaymentJourneyStatus) && (
        <Logo marketplace={data.marketplace}></Logo>
      )}
      <Routes>
        <Route path="customer-details" element={<CustomerDetails {...data} />} />
        <Route
          path="payment"
          element={
            <Payment
              marketPlaceName={data.marketplace.name}
              paymentDetails={data.paymentDetails.payment}
              paymentOptions={data.marketplace.paymentOptions}
              selectedPaymentOption={data.orderDetails.selectedPayment}
              riskDecision={data.buyerRiskDecision}
              orderDetails={data.orderDetails}
              lastCompletedStep={data.paymentJourney.lastCompletedStep}
              paymentCancelledRedirectUrl={data.paymentJourney.paymentCancelledRedirectUrl}
              marketplaceRepaymentsBankAccount={data.marketplace.repaymentsBankAccount}
            />
          }
        />
        <Route path="in-review" element={<InReview />} />
        <Route
          path="insufficient-funds-onboarding-checks-are-required"
          element={<InsufficientFundsOnboardingChecksAreRequired />}
        />
        <Route path="onboarding-checks-failed" element={<OnboardingChecksAreFailed />} />
        <Route path="insufficient-funds" element={<InsufficientFunds />} />
        <Route path="insufficient-funds-other" element={<InsufficientFundsOther />} />
        <Route path="ineligible" element={<Ineligible />} />
        <Route path="invalid" element={<InvalidOrderStatus />} />
        <Route path="unverified-sole-trader" element={<Ineligible />} />
        <Route path="mfa-polling" element={<MfaConfirmationPolling />} />
        <Route path="mfa-expired" element={<MfaExpired />} />
        <Route path="mfa-approved" element={<MfaApproved />} />
        <Route path="mfa-failed" element={<MfaRejected />} />
        <Route path="mfa-not-supported" element={<MfaNotSupported />} />
        <Route path="*" element={<Navigate to="customer-details" replace />} />
      </Routes>
      <Box sx={{ minHeight: { xs: theme.spacing(11), mobile: theme.spacing(13) } }}></Box>
    </>
  )
}

export default Order
