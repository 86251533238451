import { useCallback, useEffect } from 'react'
import SegmentWrapper from '../utils/metrics/segmentWindow'

const useTrackEvent = (initialEventName?: string) => {
  const triggerEvent = useCallback((eventName: string) => {
    SegmentWrapper.track(eventName)
  }, [])

  useEffect(() => {
    if (initialEventName) {
      triggerEvent(initialEventName)
    }
  }, [initialEventName, triggerEvent])

  return triggerEvent
}

export default useTrackEvent
