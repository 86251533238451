import React from 'react'
import Typography from '@mui/material/Typography'
import SystemErrors from './SystemErrors'
import Link from '@mui/material/Link'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const InternalServerError = () => {
  useTrackEvent(HotJarEventType.UNEXPECTED_ERROR)

  return (
    <SystemErrors title="Oops... something went wrong">
      <Typography variant="errorBody">
        Sorry for the inconvenience. Please{' '}
        <Link href="mailto:customersupport@kriya.co" variant="errorBody">
          contact us
        </Link>{' '}
        if you need any support.
      </Typography>
    </SystemErrors>
  )
}

export default InternalServerError
