import React from 'react'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import OrderErrors, { ErrorMessage } from '../error/OrderErrors'
import Link from '@mui/material/Link'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const MfaNotSupported = () => {
  useTrackEvent(HotJarEventType.MFA_NOT_SUPPORTED)
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  const errorHeader = `Unable to complete order`

  return (
    <OrderErrors
      errorHeader={errorHeader}
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Currently, order placement is disabled for your organisation. To activate it, please contact{' '}
        <Link href="mailto:customersupport@kriya.co" variant="errorBody">
          customersupport@kriya.co
        </Link>
        .
      </ErrorMessage>
    </OrderErrors>
  )
}

export default MfaNotSupported
