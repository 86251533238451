import React from 'react'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const InvalidOrderStatus = () => {
  useTrackEvent(HotJarEventType.ORDER_STATUS_INVALID)

  const paymentJourneyData = useFetchCacheData()
  const marketPlaceName = paymentJourneyData?.marketplace?.name
  const redirectUrl = paymentJourneyData?.paymentJourney.paymentDeclinedRedirectUrl

  return (
    <OrderErrors marketplaceName={marketPlaceName} returnToMarketPlaceUrl={redirectUrl}>
      <ErrorMessage>
        The order you tried to select a payment for is not valid this time. Please return to {marketPlaceName as string}
        . Thank you.
      </ErrorMessage>
    </OrderErrors>
  )
}

export default InvalidOrderStatus
