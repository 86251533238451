export enum HotJarEventType {
  RISK_REVIEW = 'checkout_risk_review',
  RISK_REJECTED = 'checkout_risk_rejected',
  ORDER_STATUS_INVALID = 'checkout_order_status_invalid',
  INSUFFICIENT_FUNDS = 'checkout_insufficient_funds',
  ORDER_NOT_FOUND = 'checkout_order_not_found',
  UNEXPECTED_ERROR = 'checkout_unexpected_error',
  INVALID_LINK = 'checkout_invalid_link',
  ONBOARDING_CHECKS_FAILED = 'checkout_onboarding_checks_failed',
  MFA_EXPIRED = 'checkout_mfa_expired',
  MFA_NOT_SUPPORTED = 'checkout_mfa_not_supported',
  MFA_REJECTED = 'checkout_mfa_rejected'
}
