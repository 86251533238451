import { useEffect } from 'react'
import SegmentWrapper from '../utils/metrics/segmentWindow'
import { PaymentJourneyInfo } from '../types/PaymentJourneyInfo'
import { v4 as uuidv4 } from 'uuid'

const useIdentify = (data: PaymentJourneyInfo | undefined) => {
  useEffect(() => {
    if (data === undefined) return
    SegmentWrapper.identify(data.orderDetails?.shortCode || uuidv4(), {
      isEmployee: localStorage.getItem('admin_access_token') !== null,
      merchant: data.marketplace?.name || 'Unknown',
      buyerId: data.orderDetails?.company?.kriyaCompanyIdentifier
    })
  }, [data])
}

export default useIdentify
