import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const InReview = () => {
  useTrackEvent(HotJarEventType.RISK_REVIEW)

  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  const errorHeader = `Review in progress`

  return (
    <OrderErrors
      errorHeader={errorHeader}
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Our team is conducting a manual review of your data. Once the evaluation is complete, we'll notify you via
        email. For any assistance in the meantime, reach out to us at{' '}
        <Link href="mailto:customersupport@kriya.co" variant="errorBody">
          customersupport@kriya.co
        </Link>{' '}
      </ErrorMessage>
    </OrderErrors>
  )
}

export default InReview
