import React, { useEffect } from 'react'
import { Navigate, PathMatch } from 'react-router-dom'
import { PaymentJourneyStatus, TerminalPaymentJourneyStatuses } from '../types/enums/PaymentJourneyStatus'
import { PaymentJourney } from '../types/PaymentJourney'
import {
  GetActiveOrderStatusRedirection,
  GetTerminalOrderStatusRedirection
} from '../pages/error/OrderStatusRedirection'

const Redirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return null
}

const PageGuard = (currentPage: PathMatch<'shortCode'> | null, paymentJourney: PaymentJourney) => {
  let redirectTo = undefined

  const status = paymentJourney.status as PaymentJourneyStatus

  if (
    status === PaymentJourneyStatus.EligibilityCheckIsRequired ||
    status === PaymentJourneyStatus.OnboardingIsRequired ||
    status === PaymentJourneyStatus.OnboardingChecksAreRequired
  ) {
    return <Redirect url={paymentJourney.onboardingJourneyUrl!} />
  }

  if (TerminalPaymentJourneyStatuses.includes(status)) {
    redirectTo = GetTerminalOrderStatusRedirection(status)
  } else {
    redirectTo = GetActiveOrderStatusRedirection(status)
  }

  if (currentPage && redirectTo !== undefined) {
    if (currentPage.pathname.indexOf(redirectTo) < 0) {
      const redirectToPath = currentPage.pathnameBase + '/' + redirectTo
      return <Navigate to={redirectToPath} replace />
    }
  }

  return null
}

export default PageGuard
