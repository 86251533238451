import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventTypes'

const OnboardingChecksAreFailed = () => {
  useTrackEvent(HotJarEventType.ONBOARDING_CHECKS_FAILED)

  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  return (
    <OrderErrors
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
      errorHeader="Onboarding failed"
    >
      <ErrorMessage>Sorry, but you did not successfully pass onboarding.</ErrorMessage>
      <ErrorMessage>
        Unfortunately, we can't process your request through Kriya at this time. Please go back to{' '}
        {paymentJourneyData.marketplace.name} and select a different payment method to finalise your order.
      </ErrorMessage>
    </OrderErrors>
  )
}

export default OnboardingChecksAreFailed
